const _ = require('lodash');
const prodConfig = require('./env.prod');
const devConfig = require('./env.dev');

const ENV = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
};

class Config {
  EBID_TIMER = true;
  PREBID_PAGE_SIZE = 9999;
  COMMIT_HASH = '01b53e6';
  WEBAPP_ITEM_ROUTE_PATH = 'auction-item'; // must be same as `packages/web/pages/<auction-items>`
  WEBAPP_AUCTION_ROUTE_PATH = 'auctions'; // must be same as `packages/web/pages/<auctions>`
  SHOW_DEBUG_INFO = false;
  CURRENT_ENV = 'production';
  ITEMS_LISTING_PERPAGE = 50;
  IS_OLD_DESIGN = false; // Use v1 design
  DASHBOARD_UPCOMING_AUCTIONS_COUNT = 4;
  FB_PIXEL_ID = '281388226028546';
  GA_TRACKING_ID = 'G-RY4RV68SJQ';
  CAN_ATTEND_LIVE_AUCTION = true;
  PLACE_BID_VIA_RTC = true;

  SENTRY = {
    ENABLED: false,
    AUTH_TOKEN: 'ebcc104573b4489591c83f123f366ef703732ca79442497c8babcc14afa6e534',
    CLIENT_DSN: 'https://5cb26ab764ce4c60982bc40f90e91538@o484306.ingest.sentry.io/6540783',
    SSR_DSN: 'https://6427fb948b8e4054bb46fce2e4140ae7@o484306.ingest.sentry.io/6511631',
    CONTENT_SECURITY_POLICY:
      'https://o484306.ingest.sentry.io/api/6511631/security/?sentry_key=6427fb948b8e4054bb46fce2e4140ae7',
  };

  FPX = {
    SELLER_ID: '',
    EXCHANGE_ID: '',
    SELLER_BANK_CODE: '01',
    PRODUCT_DESCRIPTION: 'AccountTopup',
    AR_FORM_URL: 'https://uat.mepsfpx.com.my/FPXMain/seller2DReceiver.jsp',
    TNC_URL: 'https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp',
    API_BANK_LIST_URL: '',
    API_ORDER_URL: '',
    API_SIGNATURE_GENERATOR_URL: '',
    MIN_DEPOSIT_AMOUNT: 2,
    MAX_DEPOSIT_AMOUNT: 10000,
    MOBILE_FORM_REDIRECT_URL: '/payment/fpx/mobile-redirect',
  }

  constructor() {
    return this.getConfig();
  }

  getConfig = (env) => {
    let defaultConfig = prodConfig;
    if (env) {
      const selectedProd = env === ENV.PRODUCTION;
      this.CURRENT_ENV = selectedProd ? ENV.PRODUCTION : ENV.STAGING;
      defaultConfig = selectedProd ? prodConfig : devConfig;
    }
    return _.merge(this, defaultConfig);
  };

  setConfig = (env) => {
    this.CURRENT_ENV = env;
    const config = env === ENV.PRODUCTION ? prodConfig : devConfig;
    return _.merge(this, config);
  };

  getCurrentEnv = () => {
    return this.CURRENT_ENV;
  };

  getCurrentProcessEnv = () => {
    return this.PROCESS_ENV;
  };

  isValidEnv = (env) => {
    return [ENV.PRODUCTION, ENV.STAGING, ENV.DEVELOPMENT].includes(env);
  };

  setApplicationEnvironment = (env) => {
    this.CURRENT_ENV = env;
    return this;
  };

  isProdAPI = () => {
    return this.CURRENT_ENV === ENV.PRODUCTION;
  };

  isTestAPI = () => {
    return this.CURRENT_ENV === ENV.STAGING;
  };

  setProcessEnvironment = (env) => {
    if (env) {
      this.PROCESS_ENV = env;
    }
    return this;
  };

  isProd = () => {
    return this.PROCESS_ENV === ENV.PRODUCTION;
  };

  isStaging = () => {
    return this.PROCESS_ENV === ENV.STAGING;
  };

  isLocal = () => {
    return this.PROCESS_ENV === ENV.DEVELOPMENT;
  };

  canAttendLiveAuction = () => {
    return this.CAN_ATTEND_LIVE_AUCTION;
  };

  getCodePushDeploymentKey = (os) => {
    switch (os) {
      case 'android': {
        return this.CODEPUSH_ANDROID_DEPLOYMENT_KEY;
      }
      case 'ios': {
        return this.CODEPUSH_IOS_DEPLOYMENT_KEY;
      }
    }
    return null;
  };
}

const configInstance = new Config();

module.exports = configInstance;
